import Layout from 'Components/Site/Layout/Layout';
import React, { useEffect } from 'react';
import './ProjectView.css';
import { Project } from 'Models/Project';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useScrollToTop } from 'Utils/useScrollToTop';

interface ProjectViewProps {
    project: Project;
}

const ProjectView: React.FC<ProjectViewProps> = ({ project }) => {
    // Add your component logic here
    useScrollToTop();
    const navigate = useNavigate();
    return (
        <Layout darkmode={true}>
            <Helmet>
                <title>MOGEE | Project {project.title}</title>
                <meta property="og:image" content={project.image} />
            </Helmet>
            <div className="project-page-area">
                <div className="project-page-info-bar">
                    <button className="project-page-prev-btn" onClick={() => navigate(-1)}>
                        <span className="material-symbols-rounded project-page-prev-icon">arrow_back</span>
                    </button>
                    <h1 className="main-project-title-h1"> <a href={project.website} target="_blank" rel="noopener noreferrer" >{project.title}</a> </h1>
                </div>
                <div className="project-main-img-area">
                    <img src={project.image} alt="Dashboard 10-Things" className={`project-main-img ${project.customIMGClasses}`} />
                </div>
                <div className="project-main-content-area">
                    <div className="project-main-info-table-div">
                        <div className="project-main-info-table-bar">
                            <div className="project-main-info-left-row">
                                <p className="project-main-info-var-txt-p">Klant</p>
                                <p className="project-main-info-val-txt-p">{project.customer}</p>
                            </div>
                            <div className="project-main-info-right-row">
                                <p className="project-main-info-var-txt-p">Service</p>
                                <p className="project-main-info-val-txt-p">{project.service}</p>
                            </div>
                        </div>
                        <div className="project-main-info-table-bar">
                            <div className="project-main-info-left-row">
                                <p className="project-main-info-var-txt-p">Gebruikte Talen</p>
                                <ul className="project-main-info-val-listing-ul">
                                    {project.languages.map((language, index) => (
                                        <li key={index} className="project-main-info-val-listing-li">
                                            <img src={language.image} alt={language.name} className="project-main-info-val-img" />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="project-main-info-right-row">
                                <p className="project-main-info-var-txt-p">Programmeur(s)</p>
                                <p className="project-main-info-val-txt-p">
                                    <ul className="project-main-info-val-listing-ul">
                                        {/* <li className="project-main-info-val-listing-li">
                                            <img src="assets/img/team/constant.jpg" className="project-main-persoon-val-img" />
                                        </li> */}
                                        {project.programmers}
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p className="project-main-introductie-title-txt-p">Introductie</p>
                    <p className="project-main-introductie-txt-p">{project.introduction}</p>
                    <p className="project-main-introductie-title-txt-p">Oplossing</p>
                    <p className="project-main-introductie-txt-p">{project.solution}</p>
                    <p className="project-main-introductie-title-txt-p">Technologie</p>
                    <p className="project-main-introductie-txt-p">{project.tech}</p>

                    {project.website &&
                        <a href={project.website} target="_blank" rel="noopener noreferrer" className="show-website-button">
                            <button> Bekijk de website </button>
                        </a>
                    }

                </div>
            </div>
        </Layout>
    );
};

export default ProjectView;