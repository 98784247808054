import React, { useEffect, useRef } from 'react';

const CountUp: React.FC<{ targetValue: number }> = ({ targetValue }) => {
    const valueRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const duration = 10000;
        const increment = targetValue / (duration / 1000) / 60;
        let currentValue = 0;
        let startTime: number | null = null;

        const updateValue = (timestamp: number) => {
            if (!startTime) startTime = timestamp;
            const elapsedTime = timestamp - startTime;
            currentValue = Math.min(increment * elapsedTime, targetValue);

            const roundedValue = Math.round(currentValue);
            if (valueRef.current) {
                valueRef.current.textContent = roundedValue.toString();
            }

            if (currentValue < targetValue) {
                requestAnimationFrame(updateValue);
            }
        };

        requestAnimationFrame(updateValue);
    }, [targetValue]);

    return (
        <p className="index-hero-quick-info-value-txt-p">
            <span ref={valueRef} className="index-hero-quick-info-value-span">0</span>
            <span className="index-hero-quick-info-add-span">+</span>
        </p>
    );
};

export default CountUp;