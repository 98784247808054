import Layout from 'Components/Site/Layout/Layout';
import React from 'react';
import './ContactView.css';
import GoogleMapView from 'Components/Site/ContactView/GoogleMapView/GoogleMapView';
import { Input, Textarea } from '@nextui-org/react';
import { Helmet } from 'react-helmet-async';
import { createContact } from 'Api/ContactService/ContactService';
import { useScrollToTop } from 'Utils/useScrollToTop';
import mogeegroepsfoto from 'Assets/Img/mogee-groepsfoto.jpg';

const ContactView: React.FC = () => {
    useScrollToTop();
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [comments, setComments] = React.useState('');

    const [nameError, setNameError] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [phoneError, setPhoneError] = React.useState('');
    const [commentsError, setCommentsError] = React.useState('');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let error = false;
        if (name === '') {
            setNameError('Vul je naam in');
            error = true;
        } else {
            setNameError('');
        }
        if (email === '') {
            setEmailError('Vul je e-mailadres in');
            error = true;
        } else {
            setEmailError('');
        }
        if (phone === '') {
            setPhoneError('Vul je telefoonnummer in');
            error = true;
        } else {
            setPhoneError('');
        }

        if (error === false) {
            const response = await createContact({ name, email, phone, comments });

            if (response === 'Contact created') {
                setName('');
                setEmail('');
                setPhone('');
                setComments('');
            }
        }

    }

    return (
        <Layout darkmode={true}>
            <Helmet>
                <title>MOGEE | Contact</title>
                <meta name="description" content="Neem contact op met ons softwarebedrijf in Amsterdam voor op maat gemaakte digitale oplossingen. We staan klaar om al uw vragen te beantwoorden." />
            </Helmet>
            <section className="contact-view-section">
                <div id="contact-map">
                    <GoogleMapView lat={52.366200} lng={4.854650} />
                </div>
                <div id="contact-info-content-area">
                    <h1 className="contact-title-h1">Neem contact op</h1>
                    <p className="contact-txt-p">Ben je geinteresseerd in een van onze diensten of wil je meer informatie? <br></br> Neem dan gerust contact met ons op via het onderstaande formulier!</p>
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <Input className='contact-input' type="text" label="Naam" variant="bordered" value={name} onChange={(event) => setName(event.target.value)} isInvalid={nameError !== ''} errorMessage={nameError} />
                        <Input className='contact-input' type="email" label="E-mail" variant="bordered" value={email} onChange={(event) => setEmail(event.target.value)} isInvalid={emailError !== ''} errorMessage={emailError} />
                        <Input className='contact-input' type="phone" label="Telefoon" variant="bordered" value={phone} onChange={(event) => setPhone(event.target.value)} isInvalid={phoneError !== ''} errorMessage={phoneError} />
                        <Textarea
                            className='contact-input'
                            label="Bericht"
                            placeholder="Schrijf hier je vragen of opmerkingen"
                            variant="bordered"
                            minRows={10}
                            value={comments} onChange={(event) => setComments(event.target.value)}
                        />
                        <p className="contact-accept-privacy">Door dit formulier te versturen ga je akkoord met onze Algemene Voorwaarden.</p>
                        <button type="submit" className="contact-submit-btn">Verstuur</button>
                
                    </form>
                    <div className="group-foto-area">
                        <img src={mogeegroepsfoto} alt="Mogee Groepsfoto" className="group-foto" />
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default ContactView;