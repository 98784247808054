import React, { useEffect, useState } from 'react';
import { WorkFlowStep } from '../../../../Models/WorkFlowStep';
import { DoneIcon } from '../../../../Assets/Icons/DoneIcon';

interface StepProps {
    steps: WorkFlowStep[];
}

const WorkflowSteps: React.FC<StepProps> = ({ steps }) => {
    const [currentStep, setCurrentStep] = useState<number>(0);

    const duration = 5000; // Duration in milliseconds per step

    // Function to switch steps
    const showStep = (stepIndex: number) => {
        setCurrentStep(stepIndex);
    };

    // Function to switch images with a fade-in/fade-out effect
    const fadeInOut = () => {
        const imageElement = document.getElementById('index-werkwijze-img');
        if (imageElement) {
            imageElement.style.transition = 'opacity 0.5s';
            imageElement.style.opacity = '0';
            setTimeout(() => {
                imageElement.setAttribute('src', steps[currentStep].image);
                imageElement.style.opacity = '1';
            }, 500);
        }
    };

    // Effect to handle step and image transitions
    useEffect(() => {
        const stepTimeout = setTimeout(() => {
            setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
        }, duration);

        fadeInOut();

        return () => {
            clearTimeout(stepTimeout);
        };
    }, [currentStep, steps]);

    return (
        <section className="index-workflow-section">
            <div className="index-workflow-img-area-div">
                <img
                    id="index-werkwijze-img"
                    src={steps[currentStep].image}
                    alt="workflow image"
                    className="index-workflow-img"
                />
            </div>
            <div className="index-workflow-txt-area-div">
                <h2 className="page-title-h2">Ontdek onze workflow bij MOGEE</h2>
                <ul className="index-workflow-listing-ul">
                    {steps.map((step, index) => (
                        <li
                            key={index}
                            className={`index-workflow-item-li ${currentStep === index ? 'active' : ''}`}
                        >
                            <div className="index-workflow-step-div">
                                <div className="index-workflow-inner-step-div">
                                    <DoneIcon className="index-workflow-icon" />
                                </div>
                            </div>
                            <div className="index-workflow-txt-div">
                                <h3 className="index-workflow-step-title-h3">{step.title}</h3>
                                <p className="index-workflow-step-txt-p">{step.description}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default WorkflowSteps;
