import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomeView from './Views/SiteViews/HomeView/HomeView';
import AboutUsView from './Views/SiteViews/AboutUsView/AboutUsView';
import ProjectView from './Views/SiteViews/ProjectView/ProjectView';
import ContactView from './Views/SiteViews/ContactView/ContactView';
import staticMockup10Things from './Assets/Img/Mockups/dashboard-static-10things.png';
import moocMockup from './Assets/Img/Mockups/moos-mockup.png';
import locusMockup from './Assets/Img/Mockups/locus-mockup.png';
import flutter from './Assets/Img/Talen/flutter-icon.png';
import reactjs from './Assets/Img/Talen/react-icon.png';
import typescript from './Assets/Img/Talen/ts-logo.png';
import python from './Assets/Img/Talen/python-logo.png';
import php from './Assets/Img/Talen/php-logo.png';
import laravel from './Assets/Img/Talen/laravel-logo.png';
import ruben from 'Assets/Img/Team/Ruben.jpg';
import constant from 'Assets/Img/Team/Constant.jpg';
import levy from 'Assets/Img/Team/Levy.jpg';
import marwen from 'Assets/Img/Team/Marwen.jpg';

const projects = [
  {
    url: '/project/10-things',
    title: '10-Things',
    image: staticMockup10Things,
    customer: 'MOGEE',
    service: 'Conceptualisatie, ontwerp, software (door)ontwikkeling, product design, hosting en implementatie',
    languages: [
      { name: 'Flutter', image: flutter },
      { name: 'ReactJS', image: reactjs },
      { name: 'TypeScript', image: typescript },
      { name: 'Python', image: python }
    ],
    programmers: [
      <li className="project-main-info-val-listing-li">
        <img src={constant} alt='Constant Brummer' className="project-main-persoon-val-img" />
      </li>,
      <li className="project-main-info-val-listing-li">
        <img src={ruben} alt='Ruben Sánchéz' className="project-main-persoon-val-img" />
      </li>,
      <li className="project-main-info-val-listing-li">
        <img src={levy} alt='Levy van der Linde' className="project-main-persoon-val-img" />
      </li>
    ],
    introduction: 'Dé briefing app voor de horeca. Alles wat je nodig hebt voor het geven van je dagelijkse briefing. Gebaseerd op de methodiek van Wouter Verkerk.',
    solution: "Met 10-Things wordt de dagelijkse briefing gedigitaliseerd en gedocumenteerd zodat horecaondernemers en -managers gericht kunnen sturen op het verbeteren van hun horeca succes! Op basis van jouw gekozen focuspunten genereert de app elke dag 10 gerichte taken, verdeeld over 5 categorieën, met als doel jouw KPI's te verbeteren.",
    tech: "De technologie achter 10-Things maakt het mogelijk om focuspunten te selecteren en automatisch te vertalen naar een gestructureerd actieplan voor de dag. Dankzij de integratie van een REST API en een relationele MySQL-database worden gegevens efficiënt verwerkt en opgeslagen, wat zorgt voor real-time updates en betrouwbare informatie. ",
    website: "https://10-things.nl/"
  },
  {
    url: "/project/locus",
    title: "Locus",
    image: locusMockup,
    customIMGClasses: "project-main-img-locus",
    customer: "Locus EU",
    service: "Software (door)ontwikkeling, product design, hosting, onderhoud",
    languages: [
      { name: "PHP", image: php },
      { name: "Laravel", image: laravel },
      { name: "ReactJS", image: reactjs },
    ],
    programmers: [
      <li className="project-main-info-val-listing-li">
        <img src={marwen} alt='Marwen Tlili' className="project-main-persoon-val-img" />
      </li>,
      <li className="project-main-info-val-listing-li">
        <img src={ruben} alt='Ruben Sánchéz' className="project-main-persoon-val-img" />
      </li>
    ],
    introduction: "Locus is een initiatief van jonge ondernemers in hun zoektocht naar meer efficiëntie en rendement voor vastgoedbeheerders. Ze verzamelden kennis en ervaring uit de dagelijkse praktijk en creëerden met Locus een platform dat beheer en investering zowel moeiteloos als transparanter maakt.",
    solution: "Locus EU is een krachtig Property Management System (PMS) dat speciaal is ontworpen om vastgoedbeheerders te ontlasten door het vereenvoudigen van het beheer van vastgoedportefeuilles en het onderhouden van huurderrelaties. Met Locus krijgt u de tools in handen om snel geïnformeerde beslissingen te nemen, wat resulteert in tijdsbesparing en kostenreductie. Het platform biedt een overzichtelijke aanpak voor al uw vastgoedactiviteiten, van het analyseren van data tot het uitvoeren van snelle acties via de gebruiksvriendelijke app. Door het beheer van uw vastgoed te vereenvoudigen, helpt Locus u om uw rendement te verhogen en uw zorgen te minimaliseren.",
    tech: "Locus is ontwikkeld met behulp van de nieuwste technologieën. De back-end is gebouwd met Laravel, wat zorgt voor een veilige en stabiele basis, terwijl de front-end met React.js is ontwikkeld voor een gebruiksvriendelijke ervaring. Gehost op AWS via Laravel Vapor, biedt Locus schaalbaarheid, zelfs wanneer uw vastgoedportefeuille groeit. Dankzij de integratie van AI voor property management, automatische huur- en indexatiebeheer, en naadloze boekhoudkoppelingen, biedt Locus een toekomstbestendige oplossing voor vastgoedbeheerders.",
    website: "https://www.locus.eu"
  },
  {
    url: "/project/moos",
    title: "MOOS",
    image: moocMockup,
    customer: "Nederlands evenementen-, congres- en beurscomplex",
    service: "Conceptualisatie, ontwerp, software (door)ontwikkeling, product design, hosting en implementatie",
    languages: [
      { name: "Python", image: python },
      { name: "ReactJS", image: reactjs },
    ],
    programmers: [
      <li className="project-main-info-val-listing-li">
        <img src={marwen} alt='Marwen Tlili' className="project-main-persoon-val-img" />
      </li>,
      <li className="project-main-info-val-listing-li">
        <img src={ruben} alt='Ruben Sánchéz' className="project-main-persoon-val-img" />
      </li>
    ],
    introduction: "Dé on-site order app voor evenementenlocaties. MOOS maakt het mogelijk om bestellingen te plaatsen en af te leveren op evenementenlocaties zoals de RAI Amsterdam. MOOS is een van MOGEE haar eigen producten en bestaat uit een web app met geïntegreerde API-verbinding.",
    solution: "MOOS is geboren uit de behoefte om de inefficiëntie en complexiteit van handmatige bestellingen op beurzen en evenementen te elimineren. Waar papieren bonnen voorheen zorgden voor administratieve chaos en verloren data, biedt MOOS nu een digitale oplossing om eenvoudig producten en services te kunnen bestellen. Dit verbetert niet alleen de operationele processen, maar zorgt ook voor een efficiëntere administratie en nauwkeurige dataverzameling, wat leidt tot een stijging van on-site bestellingen op evenementenlocaties.",
    tech: "MOOS is gebouwd met geavanceerde technologieën om een soepele en efficiënte ervaring te garanderen. Het platform integreert naadloos met de Momentus API, wat toegang biedt tot een breed scala aan evenement-gerelateerde gegevens die essentieel zijn voor zowel organisatoren als leveranciers. De webapplicatie, ontwikkeld met REST API-technologie en FastAPI, wordt ondersteund door een PostgreSQL-database, waardoor grote hoeveelheden gegevens efficiënt worden beheerd. De front-end is ontworpen met React, wat zorgt voor een intuïtieve gebruikerservaring. Veiligheid staat voorop, met Amazon Cognito voor betrouwbare en veilige authenticatie."
  }
]

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/over-ons" element={<AboutUsView />} />
        {projects.map((project, index) => (
          <Route key={index} path={project.url} element={<ProjectView project={project} />} />
        ))}
        <Route path="/project/10-things" element={<ProjectView />} />
        <Route path="/contact" element={<ContactView />} />
      </Routes>
    </Router>
  );
}

export default App;
